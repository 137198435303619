/* eslint-disable jsx-a11y/alt-text */
import { Icon } from "../Icon";
import { Image } from "react-datocms";
import { ScrollableImage } from "../ScrollableImage";

export const FeaturesWrapper = ({ children, dark = false, minHeight = "50vh", padding = "var(--landing-page-padding)" }) => {
    return <>
        <div className={"features " + (dark && "dark")}>
            <div className="features__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .features {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100vw;
                min-height: ${minHeight};
                background-image: var(--gradient);
            }
            :global(.dark .features) {
                background-color: var(--covalent-dark-blue);
                background-image: none;
                color: var(--text);
            }
            .dark.features {
                background-color: var(--covalent-dark-blue);
                background-image: none;
                color: var(--text);
            }
            :global(.dark .features .features__card) {
                background-color: var(--covalent-dark-blue);
            }
            :global(.dark .features .features__card) {
                background-color: var(--covalent-dark-blue);
                border: 1px solid var(--black-light);
                padding: var(--landing-page-gap);
                padding-bottom: var(--landing-page-gap);
                border-radius: var(--border-radius);
            }

            :global(.dark .features .features__card) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;
                flex: 1 1 calc(25% - 1.5rem);
                min-width: 200px;
                border-radius: calc(var(--border-radius) * 2);
                background-color: black;
                transform: translate(0);
                transform-style: preserve-3d;
                z-index: 1;
                position: relative;
                border: 1px solid var(--black-light);
                height: auto;
            }

            :global(.dark .features .features__card:hover) {
                border: 1px solid var(--pink);
            }

            @keyframes backgroundGradient {
                0% {
                    background-position: 0% 50%;
                    transform: scale(1.01);
                }
                100% {
                    background-position: 100% 50%;
                    transform: scale(0.98);
                }
            }

            :global(.dark .features .features__card::before) {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(45deg, #ff4c8b80, var(--teal));
                z-index: -1;
                filter: blur(5px);
                border-radius: calc(var(--border-radius) * 2);
                animation: backgroundGradient 4s ease-in-out infinite alternate;
                background-size: 200% 200%;
            }

            :global(.dark .features .features__card:hover::before) {
                filter: blur(25px);
                transition: all 0.2s ease-in-out;
                background-image: linear-gradient(45deg, var(--pink), var(--teal));
            }

            :global(.dark .features .features__card::after) {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--covalent-dark-blue);
                z-index: -1;
                border-radius: calc(var(--border-radius) * 2);
            }

            :global(.dark .features .features__card:hover::after) {
                background-image: linear-gradient(135deg,#00d8d521 0%,#ff4c8b33 100%);
            }

            .features__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                padding: ${padding};
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
            }
            :global(.dark .features__card .features__card__description) {
                color: var(--text);
            }

            @media (max-width: 768px) {
                .features {
                    flex-direction: column;
                    height: auto;
                }
                .features__content {
                    width: 100%;
                    height: auto;
                    padding: 0rem;
                    padding-bottom: 4rem;
                    padding-top: 4rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureTitle = ({ children }) => {

    return <>
        <h2 className={" feature__title animate-in "}>
            {children}
        </h2>
        <style jsx>
            {`
            h2 {
                font-size: var(--font-size-7);
                font-weight: var(--font-weight-bold);
                margin-bottom: 1rem;
                line-height: 1.2;
                margin-bottom: 3rem;
            }
        `}
        </style>
    </>;
};

export const FeatureHighlights = ({ children, dark = false }) => {
    return <>
        <div className={"feature-highlights " + (dark && "dark")}>
            <div className="feature-highlights__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .feature-highlights {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
            :global(.dark .feature-highlights) {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            .feature-highlights__content {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                margin: 0 auto;
                width: 100%;
                flex-wrap: wrap;
                gap: 6rem;
            }
            :global(.feature-highlight:nth-child(even) .feature-highlight__content) {
                flex-direction: row-reverse;
            }
            :global(.dark .features__card .features__card__description) {
                color: var(--text);
            }
            :global(.feature-highlight:nth-child(even) .feature-highlight__content .feature-highlight-image) {
                margin-right: auto;
                margin-left: 0;
            }
            @media (max-width: 768px) {
                .feature-highlights {
                    flex-direction: column;
                    height: auto;
                }
                .feature-highlights__content {
                    width: 100%;
                    height: auto;
                    gap: 2rem;
                }
                :global(.feature-highlight:nth-child(even) .feature-highlight__content) {
                    flex-direction: row;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlight = ({ children, dark = false }) => {
    return <>
        <div className={" feature-highlight animate-in " + (dark && "dark")}>
            <div className="feature-highlight__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            .feature-highlight {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: 25vh;
                width: 100%;
                position: relative;
            }
            :global(.dark .feature-highlight) {
                background-color: var(--covalent-dark-blue);
                color: #fff;
            }
            :global(.feature-highlight__content:not(.dark .feature-highlight__content)) {
                display: flex;
                flex-wrap: no-wrap;
                flex-direction: row;
                place-items: center;
                height: 100%;
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
                text-align: left;
                gap: var(--landing-page-gap);
                border: 1px solid var(--code-border);
                padding: 0 6rem;
                border-radius: calc(var(--border-radius) * 4);
                background: #ffffff8c;
                border: 3px solid white;
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
            }
            :global(.feature-highlight__content) {
                display: flex;
                flex-wrap: no-wrap;
                flex-direction: row;
                place-items: center;
                height: 100%;
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
                text-align: left;
                gap: var(--landing-page-gap);
                border: 1px solid var(--code-border);
                padding: 0 6rem;
                border-radius: calc(var(--border-radius) * 4);
                background-image: linear-gradient(135deg,#00d8d521 0%,#ff4c8b33 100%);
            }
            @media (max-width: 1000px) {
                :global(.features .feature-highlights .feature-highlight__content) {
                    padding: 0 2rem;
                }
            }
            @media (max-width: 768px) {
                .feature-highlight {
                    flex-direction: column;
                    height: auto;
                    margin-top: 0;
                }
                :global(.features .feature-highlights .feature-highlight__content) {
                    width: 100%;
                    height: auto;
                    flex-wrap: wrap;
                    padding: 0rem 1rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightTitle = ({ children, dark = false }) => {
    return <>
        <div className={" animate-in feature-highlight-title " + (dark && "dark")}>
            <h2 className="feature-highlight-title__content">
                {children}
            </h2>
        </div>
        <style jsx>
            {`
            .feature-highlight-title {
                font-size: var(--font-size-5);
                padding-left: 2rem;
                margin-bottom: 2rem;
                position: relative;
            }
            .feature-highlight-title:after {
                content:'';
                position:absolute;
                bottom:0;
                top: 0;
                left:0;
                background:var(--pink);
                width: 3px;
                border-radius:10px 10px 10px 10px;
            }
            :global(.dark .feature-highlight-title) {
                color: #fff;
            }
            .feature-highlight-title__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                max-width: var(--landing-page-max-width);
                margin: 0 auto;
                width: 100%;
                font-size: var(--font-size-7);
                font-weight: var(--font-weight-bold);
                margin-bottom: 1rem;
                line-height: 1.2;
            }
            @media (max-width: 768px) {
                .feature-highlight-title {
                    flex-direction: column;
                    height: auto;
                    padding-left: 0rem;
                }
                .feature-highlight-title:after {
                    width: 0;
                }
                .feature-highlight-title__content {
                    width: 100%;
                    height: auto;
                    font-size: var(--font-size-5);
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightSubtitle = ({ children, dark = false }) => {
    return <>
        <div className={" animate-in feature-highlight-subtitle " + (dark && "dark")}>
            <div className="feature-highlight-subtitle__content">
                {children}
            </div>
        </div>
        <style jsx>
            {`
            :global(.feature-highlight-subtitle__content:not(.dark)) {
               color: var(--text);
            }
            :global(.dark feature-highlight-subtitle__content) {
                color: var(--text);
            }
            .feature-highlight-subtitle__content {
                margin: 0 auto;
                width: 100%;
                color: var(--text);
                margin-bottom: 0rem;
                font-size:var(--font-size-4);
                line-height: var(--line-height-4);
            }
            @media (max-width: 768px) {
                .feature-highlight-subtitle {
                    flex-direction: column;
                    height: auto;
                }
                .feature-highlight-subtitle__content {
                    width: 100%;
                    height: auto;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightContent = ({ children, dark = false, small = false, full = false }) => {

    return <>
        <div className={"animate-in feature-highlight-content " + (dark && "dark") + (small && " small")}>
            {children}
        </div>
        <style jsx>
            {`
            :global(.dark .feature-highlight-content) {
                color: #fff;
            }
            .feature-highlight-content {
                width: ${full ? "100%" : "50%"};
                max-width: ${full ? "none" : "450px"};
                padding: 6rem 0rem;
            }
            .feature-highlight-content.small {
                max-width: 33%;
            }
            @media (max-width: 768px) {
                .feature-highlight-content {
                    max-width: 100%;
                    width: 100%;
                    padding: 1rem 0rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightLink = ({ children }) => {
    return <>
        <div className="animate-in feature-highlight-link">
            {children}
        </div>
        <style jsx>
            {`
            :global(.dark .feature-highlight-link) {
                color: #fff;
            }
            .feature-highlight-link {
                color: var(--pink);
                margin-top: 1.7rem;

            }
        `}
        </style>
    </>;
};

export const FeatureHighlightImage = ({ src, dark = false }) => {

    return <>
        <div className={"feature-highlight-image animate-in " + (dark && "dark")}>
            <Image  usePlaceholder={false} data={src} className="" />
        </div>
        <style jsx>
            {`
            .feature-highlight-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                margin: 0;
                flex: 1;
                min-width: 250px;
                max-width: 500px;
                margin-left: auto;
                margin-bottom: -3rem;
                margin-top: -3rem;
                z-index: 2;
            }
            @media (max-width: 768px) {
                .feature-highlight-image {
                    margin-left: 0;
                    min-width: 0;
                    margin-bottom: 1rem;
                    margin-top: -2rem;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightInteractiveImage = ({ images, dark = false }) => {

    return <>
        <div className={"feature-highlight-image animate-in " + (dark && "dark") + " isVisibleImage "}>
            <ScrollableImage images={images}/>
        </div>
        <style jsx>
            {`
            .feature-highlight-image {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                margin: 0;
                flex: 1;
                min-width: 250px;
                max-width: 500px;
                margin-left: auto;
            }
            :global(.feature-highlight .isVisibleImage) {
                animation: fadeInLeft 0.5s ease-in-out forwards;
            }
            :global(.feature-highlight:nth-child(odd) .isVisibleImage) {
                animation: fadeInRight 0.5s ease-in-out forwards;
            }
            @media (max-width: 768px) {
                .feature-highlight-image {
                    margin-left: 0;
                }
            }
        `}
        </style>
    </>;
};

export const FeatureHighlightVideo = ({ src, dark = false }) => {
    return <>
        <div className={"feature-highlight-video " + (dark && "dark")}>
            <video src={src} className="" autoPlay muted loop />
            <div className="fade"/>
        </div>
        <style jsx>
            {`
            .feature-highlight-video {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                margin: 0;
                flex: 1;
                min-width: 250px;
                max-width: 1000px;
                margin-left: auto;
                position: relative;
            }
            video {
                width: 100%;

            }
            .fade {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                box-shadow: 0px 0 80px 40px inset var(--covalent-dark-blue);
                z-index: 1;
            }
            @media (max-width: 768px) {
                .feature-highlight-video {
                    margin-left: 0;
                }
            }
        `}
        </style>
    </>;
};

export const FeaturesCard = ({ children, colSpan = 1, animation = true }) => {

    return <>
        <div className={"gradient-border " +  " features__card animate-in " + (!animation && " disable_animation")} >
            {children}
        </div>
        <style jsx>
            {`
            div {
                display: flex;
                grid-column: span 1 / span 1;
                flex-direction: column;
                justify-content: start;
                align-items: flex-start;
                width: 100%;
                gap: var(--landing-page-gap);
                border: 3px solid var(--white);
                padding: var(--landing-page-gap);
                border-radius: calc(var(--border-radius) * 4);
                height: auto;
                position: relative;
                background: #ffffff8c;
                cursor: default;
                flex: 1;
                text-decoration:none;
                grid-column: span ${colSpan};
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
                transition: 0.4s;
            }

            div:hover {
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
                transition: 0.4s;
            }

            :global(.gradient-border:hover:not(.dark .gradient-border)){
                background: #fff;
                border: 3px solid transparent;
                background-clip: padding-box;
                transition: all 0.25s ease-in-out;
                position: relative;
            }

            :global(.gradient-border:hover .feature-icon){
                transition: all 0.25s ease-in-out;
                opacity:100;
            }
            :global(.features__card:hover .feature-icon){
                transition: all 0.25s ease-in-out;
                opacity:100;
            }

            :global(.dark .gradient-border::before) {
                content: "";
                position: absolute;
                top: -2px;
                left: -2px;
                right: -2px;
                bottom: -2px;
                z-index: -1;
                border-radius: inherit;
                background: linear-gradient(45deg, var(--pink), var(--teal));
                background-size: 200% 200%;
                background-position: 0 50%;
                box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
            }

            :global(.dark .gradient-border:hover::before) {
                content: "";
                position: absolute;
                top: -2px;
                left: -2px;
                right: -2px;
                bottom: -2px;
                z-index: -1;
                border-radius: inherit;
                background: linear-gradient(45deg, var(--pink), var(--teal));
                background-size: 200% 200%;
                background-position: 0 50%;
                opacity: 1;
            }

            // animate background linear gradient to rotate write css animation keyframes
            @keyframes animatedBackground {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
            }

            @media (max-width: 768px) {
                div {
                    flex-direction: column;
                    place-items: start;
                    min-width:0;
                    grid-column: span 1;
                }
            }
        `}
        </style></>;
};


export const FeaturesCards = ({ children, grid = 3 }) => {
    return <>
        <div className="features__cards">
            {children}
        </div>
        <style jsx>
            {`
            div {
                display: grid;  
                grid-template-columns: repeat(${grid}, minmax(0, 1fr));
                gap: var(--landing-page-gap);
                width: 100%;
            }
            @media (max-width: 1250px) {
                div {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
            @media (max-width: 768px) {
                div {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                }
            }
        `}
        </style>
    </>;
};

export const FeaturesCardCTA = ({ children }) => {
    return <>
        <div>
            {children}
        </div>
        <style jsx>
            {`
            div {
                margin-top: auto;
            }
        `}
        </style>
    </>;
};

export const FeaturesCardTitle = ({ children, link = "" }) => {
    return <>
        <div className="title">
            <h3>{children}</h3>
            {(link && <div className="feature-icon flex-center">
                <Icon icon={"chevron-right"} color="var(--pink)" />
            </div>)}
        </div>
        <style jsx>
            {`
            .title{
                display:flex;
                align-items:center;
                gap: 0.5rem;
                justify-content:space-between;
                width:100%;
                color: var(--text);
            }
            :global(.dark .title) {
                color: var(--pink);
            }
            .feature-icon{
                opacity: 0;
                transition: all 0.25s ease-in-out;
            }
            h3 {
                font-size: var(--font-size-5);
                line-height: 1.4;
                margin-bottom: 1rem;
                margin-top: 0;
                margin: 0;
                font-weight: var(--font-weight-bold);
            }
        `}
        </style>
    </>;
};

export const FeaturesCardDescription = ({ children }) => {
    return <>
        <p className="features__card__description">
            {children}
        </p>
        <style jsx>
            {`

            .features__card__description {
                color: var(--text);
                font-size:var(--font-size-4);
                line-height: var(--line-height-4);
            }
        `}
        </style>
    </>;
};